<template>
	<div data-route>
		<page-header
			heading="Edit article"
		/>
		<div data-element="main">
			<article-edit
				:articleId="getArticleId"
				:cancelRoute="getCancelRoute"
				:isCreate="false"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader   from '@/components/ui/PageHeader';
	import ArticleEdit  from '@/components/knowledgebase/edit/ArticleEdit';

	export default {
		metaInfo: {
			title: 'Edit category'
		},
		components: {
			PageHeader,
			ArticleEdit
		},
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/articles';
			},
			getArticleId () {
				return this.$route.params.articleId;
			}
		},
		created () {}
	};
</script>
